import { Button } from '../components/Button'
import { AssetBox } from '../components/AssetBox'
import { TransactionInfoTable } from '../components/TransactionInfoTable'
import { IconArrowDown, IconDeposit, IconPlus } from '../components/icons'
import { currencies } from '../data/mock/currencies'

export const Provide = () => {
  return (
    <div className="container">
      <div className="flex max-w-3xl mx-auto mb-4">
        <AssetBox
          balance={1234103}
          amount={1000}
          currencies={currencies}
          showMax
          onInputChange={() => {}}
          onCurrencyChange={() => {}}
          className="flex-auto w-1/2"
        />

        <div className="flex-shrink-0 flex justify-center items-center w-20 text-md">
          <IconPlus />
        </div>

        <AssetBox
          balance={234103}
          amount={1026.01065}
          currencies={currencies}
          onInputChange={() => {}}
          onCurrencyChange={() => {}}
          className="flex-auto w-1/2"
        />
      </div>

      <div className="flex max-w-3xl mx-auto mb-8">
        <div className="flex-auto flex justify-end items-baseline w-1/2">
          <span className="mr-4 text-gray-500 font-bold">Transaction Fee:</span>

          <Button outlined size="small" endIcon={<IconArrowDown />}>
            Luna
          </Button>
        </div>

        <div className="flex-shrink-0 w-20"></div>

        <div className="flex-auto w-1/2">
          <TransactionInfoTable
            rows={[
              {
                label: 'Luna price',
                value: '1.161793 Luna per LP'
              },
              {
                label: 'bLUNA price',
                value: '1.192693 bLUNA per LP'
              },
              {
                label: 'LP from Tx',
                value: '860.738078 LP',
                infoText:
                  'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
              },
              {
                label: 'Pool Share after Tx',
                value: '0.27 %',
                infoText:
                  'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
              },
              {
                label: 'Tx Fee',
                value: '0.006798 Luna',
                infoText:
                  'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
              }
            ]}
          />
        </div>
      </div>

      <p className="max-w-lg mx-auto mb-8 text-sm text-center text-white italic opacity-50">
        The displaying number is the simulated result and can be different from
        the actual swap rate. Trade at your own risk.
      </p>

      <div className="text-center">
        <Button startIcon={<IconDeposit />}>Provide Ocean</Button>
      </div>
    </div>
  )
}
