import React, { createContext } from 'react'
import { createClient } from '../services/client.service'
import { createWallet, WalletService } from '../services/wallet.service'

const client = createClient()
const wallet = createWallet(client)

export const WalletContext = createContext(
  {} as {
    wallet: WalletService
  }
)

export const WalletContextProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  return (
    <WalletContext.Provider value={{ wallet }}>
      {children}
    </WalletContext.Provider>
  )
}
