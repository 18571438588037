import classNames from 'classnames'

export type SvgIconProps = {
  className?: string
}

export const SvgIcon = ({
  children,
  className
}: { children?: React.ReactNode } & SvgIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      aria-hidden="true"
      focusable="false"
      role="img"
      className={classNames(
        'w-1em h-1em flex-shrink-0 fill-current transition-fill select-none',
        className
      )}>
      {children}
    </svg>
  )
}
