import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Listbox, Option } from '../components/Listbox'
import { IconArrowDown } from '../components/icons'
import { escapeRegExp } from '../utils/other'

type Balance = number | { label: string; amount: number }

export type AssetBoxProps = {
  title?: string
  balance?: Balance
  amount?: number | string
  currency?: string
  currencySelectorLabel?: string
  currencies: Option[]
  showMax?: boolean
  highlight?: boolean
  onInputChange: (value?: number | string) => void
  onCurrencyChange: (value?: string) => void
  className?: string
}

const inputRegex = new RegExp('^\\d*(?:\\\\[.])?\\d*$')

export const AssetBox = ({
  title = 'Asset',
  balance,
  amount,
  currency,
  currencySelectorLabel = 'Select a Token',
  currencies,
  showMax = false,
  highlight = false,
  onInputChange,
  onCurrencyChange,
  className
}: AssetBoxProps) => {
  const [assetBalance, setAssetBalance] =
    useState<{ label: string; amount: number } | undefined>()
  const [selectedCurrency, setSelectedCurrency] = useState<Option | undefined>()

  const handleOnClickMax = () => {
    if (assetBalance?.amount) {
      onInputChange(assetBalance.amount)
    }
  }

  useEffect(() => {
    if (!balance) {
      setAssetBalance(undefined)
      return
    }

    if (typeof balance === 'string' || typeof balance === 'number') {
      setAssetBalance({ label: 'Balance', amount: balance })
    } else {
      setAssetBalance(balance)
    }
  }, [balance])

  useEffect(() => {
    if (!currency) {
      setSelectedCurrency(undefined)
      return
    }

    const selected = currencies.find(({ value }) => value === currency)

    setSelectedCurrency(selected)
  }, [currencies, currency])

  return (
    <article
      className={classNames(
        'p-4 text-gray-500 border rounded-lg',
        highlight ? 'border-blue-500' : 'border-white',
        className
      )}>
      <div className="mb-4 flex items-baseline">
        <h4 className="mr-auto font-bold leading-none">{title}</h4>

        {assetBalance && (
          <span className="font-semibold text-sm leading-none">
            {assetBalance.label} {assetBalance.amount}
          </span>
        )}

        {showMax && assetBalance && (
          <button
            className="ml-3 font-semibold text-sm text-blue-500 hover:text-blue-600 leading-none transition-colors"
            onClick={handleOnClickMax}>
            Max
          </button>
        )}
      </div>

      <div className="flex items-center text-white">
        <input
          className="flex-auto w-auto min-w-0 max-w-none text-xl leading-none bg-transparent appearance-none input-remove-arrows placeholder-gray-500 focus:outline-none"
          type="text"
          value={amount || ''}
          placeholder="0.0"
          size={1}
          minLength={1}
          maxLength={79}
          inputMode="decimal"
          pattern="^[0-9]*[.,]?[0-9]*$"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          onChange={(event) => {
            const value = event.target.value.replace(/,/g, '.')

            if (value === '' || inputRegex.test(escapeRegExp(value))) {
              onInputChange(value)
            }
          }}
        />

        <Listbox
          className="flex-shrink-0 ml-4"
          selected={currency}
          options={currencies}
          position="right"
          onChange={onCurrencyChange}>
          <button className="flex items-center">
            {selectedCurrency?.icon && (
              <span className="mr-2 text-xl leading-none">
                {selectedCurrency?.icon}
              </span>
            )}

            <span className="text-lg leading-none font-semibold">
              {selectedCurrency
                ? selectedCurrency.label
                : currencySelectorLabel}
            </span>

            <IconArrowDown className="ml-4" />
          </button>
        </Listbox>
      </div>
    </article>
  )
}
