import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { SiteContext } from './context/SiteContext'
import { Dashboard } from './pages/Dashboard'
import { Swap } from './pages/Swap'
import { Provide } from './pages/Provide'
import { Withdraw } from './pages/Withdraw'
import { Header } from './components/Header'

const PUBLIC_URL = process.env.PUBLIC_URL

function App() {
  const { title } = useContext(SiteContext)

  return (
    <main className="mb-34">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Router>
        <Header
          links={[
            { title: 'Dashboard', to: '/' },
            { title: 'Swap', to: '/swap/' },
            { title: 'Provide', to: '/provide/' },
            { title: 'Withdraw', to: '/withdraw/' }
          ]}
        />

        <Switch>
          <Route path="/swap">
            <Helmet>
              <title>Swap - {title}</title>
              <link rel="canonical" href={`${PUBLIC_URL}/swap/`} />
            </Helmet>

            <Swap />
          </Route>

          <Route path="/provide">
            <Helmet>
              <title>Provide - {title}</title>
              <link rel="canonical" href={`${PUBLIC_URL}/provide/`} />
            </Helmet>

            <Provide />
          </Route>

          <Route path="/withdraw">
            <Helmet>
              <title>Withdraw - {title}</title>
              <link rel="canonical" href={`${PUBLIC_URL}/withdraw/`} />
            </Helmet>

            <Withdraw />
          </Route>

          <Route path="/">
            <Helmet>
              <title>Dashboard - {title}</title>
              <link rel="canonical" href={`${PUBLIC_URL}/`} />
            </Helmet>

            <Dashboard />
          </Route>
        </Switch>
      </Router>
    </main>
  )
}

export default App
