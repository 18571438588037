import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Logo } from './Logo'
import { HeaderMenu, HeaderMenuProps } from './HeaderMenu'
import { ButtonWallet } from './ButtonWallet'

export type HeaderProps = {
  className?: string
} & Pick<HeaderMenuProps, 'links'>

export const Header = ({ links, className }: HeaderProps) => {
  return (
    <header className={classNames('pt-8 mb-34', className)}>
      <div className="container flex items-center">
        <Link to="/" className="flex-shrink-0">
          <Logo className="w-auto h-20" />
        </Link>

        <div className="flex flex-auto justify-between items-center mt-1.5">
          <HeaderMenu links={links} className="mx-auto" />

          <ButtonWallet />
        </div>
      </div>
    </header>
  )
}
