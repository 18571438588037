import { Fragment } from 'react'
import classNames from 'classnames'
import { Listbox as HuiListbox, Transition } from '@headlessui/react'
import { IconCheck } from './icons'

export interface Option {
  label: React.ReactNode
  value: string
  icon?: React.ReactNode
  disabled?: boolean
}

export type ListboxProps = {
  label?: string
  children: React.ReactElement
  options: Option[]
  selected?: string
  position?: 'left' | 'center' | 'right'
  onChange: (value: string) => void
  className?: string
}

export const Listbox = ({
  label,
  children,
  options,
  selected,
  position = 'left',
  onChange,
  className
}: ListboxProps) => {
  return (
    <HuiListbox
      as="div"
      className={classNames('relative inline-block', className)}
      value={selected}
      onChange={onChange}>
      {label && <HuiListbox.Label>{label}</HuiListbox.Label>}

      <HuiListbox.Button as={Fragment}>{children}</HuiListbox.Button>

      <Transition
        as={Fragment}
        enter="transition duration-1000"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <HuiListbox.Options
          className={classNames(
            'absolute w-44 mt-2 py-2 text-white bg-gray-900 rounded-lg shadow z-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-current focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800',
            {
              'left-0 origin-top-left': position === 'left',
              'left-1/2 transform -translate-x-1/2 origin-top':
                position === 'center',
              'right-0 origin-top-right': position === 'right'
            }
          )}>
          {options.map(({ icon, label, value, disabled }) => (
            <HuiListbox.Option
              key={value}
              value={value}
              disabled={disabled}
              as={Fragment}>
              {({ active, selected, disabled }) => (
                <li
                  className={classNames(
                    'flex items-center w-full px-3 py-1.5 font-semibold transition-colors',
                    {
                      'bg-gray-800': active,
                      'text-blue-500': selected,
                      'opacity-50 pointer-events-none': disabled
                    }
                  )}>
                  <span className="flex-shrink-0 w-4 mr-3 text-md leading-none">
                    {icon}
                  </span>

                  <span className="mr-auto">{label}</span>

                  {selected && (
                    <IconCheck className="flex-shrink-0 ml-3 text-sm leading-none" />
                  )}
                </li>
              )}
            </HuiListbox.Option>
          ))}
        </HuiListbox.Options>
      </Transition>
    </HuiListbox>
  )
}
