import React from 'react'
import classNames from 'classnames'
import { useFlexLayout, useTable } from 'react-table'
import { Heading } from '../../components/Heading'

export type TableProps = {
  title: React.ReactNode
  columns: any
  data: any
  highlight?: boolean
  className?: string
}

export const Table = ({
  title,
  columns,
  data,
  highlight = false,
  className
}: TableProps) => {
  const tableInstance = useTable({ columns, data }, useFlexLayout)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  return (
    <section className={classNames('', className)}>
      <Heading className="ml-6 mb-4">{title}</Heading>

      <div
        {...getTableProps()}
        className={classNames('px-4 shadow rounded-lg', {
          'bg-gray-700': highlight,
          'border border-gray-700': !highlight
        })}
        role="grid">
        <div className="pt-6 pb-2 text-gray-500 font-bold" role="rowgroup">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} role="row">
              {headerGroup.headers.map((column) => (
                <div
                  {...column.getHeaderProps()}
                  className="px-2"
                  role="columnheader">
                  {column.render('Header')}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} role="rowgroup">
          {rows.map((row, index) => {
            prepareRow(row)

            return (
              <div
                {...row.getRowProps()}
                className={classNames(
                  'py-6 border-t ',
                  index === 0 ? 'border-gray-500' : 'border-gray-700'
                )}
                role="row">
                {row.cells.map((cell) => {
                  return (
                    <div
                      {...cell.getCellProps()}
                      className="px-2"
                      role="gridcell">
                      {cell.render('Cell')}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
