import classNames from 'classnames'
import { IconInfo } from '../components/icons'

interface Row {
  label: string
  value: string
  infoText?: string
}

export type TransactionInfoTableProps = {
  rows: Row[]
  className?: string
}

export const TransactionInfoTable = ({
  rows,
  className
}: TransactionInfoTableProps) => {
  return (
    <table className={classNames('w-full text-sm', className)}>
      <tbody>
        {rows.map(({ label, value, infoText }, index) => {
          const rowMb = rows.length - 1 !== index ? 'pb-2.5' : null

          return (
            <tr key={index}>
              <th
                className={classNames('p-0 text-left font-normal', rowMb)}
                scope="row">
                <span className="flex items-center">
                  {label}
                  {infoText && <IconInfo className="ml-2" />}
                </span>
              </th>

              <td className={classNames('p-0 text-right', rowMb)}>{value}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
