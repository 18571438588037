import { useEffect, useMemo, useState } from 'react'
import { Button } from '../components/Button'
import { AssetBox } from '../components/AssetBox'
import { Listbox, Option } from '../components/Listbox'
import { TransactionInfoTable } from '../components/TransactionInfoTable'
import {
  IconArrowDown,
  IconArrowRight,
  IconSwap,
  IconSwapSolid
} from '../components/icons'
import { currencies } from '../data/mock/currencies'

interface SwapAsset {
  balance?: number
  amount?: number | string
  amountParsed?: number
  currency?: string
}

export const Swap = () => {
  const [rate] = useState<number>(1.25)
  const [feeCurrency, setFeeCurrency] = useState<string | undefined>('luna')
  const [selectedFeeCurrency, setSelectedFeeCurrency] =
    useState<Option | undefined>()

  const [from, setFrom] = useState<SwapAsset>({
    balance: 123456789,
    amount: 0,
    currency: 'luna'
  })

  const [to, setTo] = useState<SwapAsset>({})

  const fromCurrencies = useMemo(() => {
    return currencies.map((currency) => {
      if (currency.value === to.currency) {
        return { ...currency, disabled: true }
      }

      return currency
    })
  }, [to.currency])

  const toCurrencies = useMemo(() => {
    return currencies.map((currency) => {
      if (currency.value === from.currency) {
        return { ...currency, disabled: true }
      }

      return currency
    })
  }, [from.currency])

  const switchFromTo = () => {
    setFrom(to)
    setTo(from)
  }

  useEffect(() => {
    if (!feeCurrency) {
      setSelectedFeeCurrency(undefined)
      return
    }

    const selected = currencies.find(({ value }) => value === feeCurrency)

    setSelectedFeeCurrency(selected)
  }, [feeCurrency])

  return (
    <div className="container">
      <div className="flex max-w-3xl mx-auto mb-4">
        <AssetBox
          balance={from.balance}
          amount={from.amount}
          currency={from.currency}
          currencies={fromCurrencies}
          showMax
          onInputChange={(value) => {
            const parsedValue = parseFloat(String(value))

            if (isNaN(parsedValue)) {
              setFrom({ ...from, amount: value, amountParsed: undefined })
              setTo({ ...to, amount: undefined, amountParsed: undefined })
              return
            }

            const toAmount = parsedValue * rate

            setFrom({ ...from, amount: value, amountParsed: parsedValue })
            setTo({ ...to, amount: toAmount, amountParsed: toAmount })
          }}
          onCurrencyChange={(value) => {
            setFrom({ ...from, currency: value })
          }}
          className="flex-auto w-1/2"
        />

        <div className="flex-shrink-0 flex justify-center items-center w-20">
          <button
            className="group relative w-10 h-10 text-blue-500"
            onClick={switchFromTo}
            aria-label="Switch">
            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-md group-hover:opacity-0 transition-opacity">
              <IconArrowRight />
            </span>

            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-lg opacity-0 group-hover:opacity-100 transition-opacity">
              <IconSwapSolid />
            </span>
          </button>
        </div>

        <AssetBox
          balance={to.balance}
          amount={to.amount}
          currency={to.currency}
          currencies={toCurrencies}
          highlight
          onInputChange={(value) => {
            const parsedValue = parseFloat(String(value))

            if (isNaN(parsedValue)) {
              setTo({ ...to, amount: value, amountParsed: undefined })
              setFrom({ ...from, amount: undefined, amountParsed: undefined })
              return
            }

            const fromAmount = parsedValue * (1 / rate)

            setTo({ ...to, amount: value, amountParsed: parsedValue })
            setFrom({ ...from, amount: fromAmount, amountParsed: fromAmount })
          }}
          onCurrencyChange={(value) => {
            setTo({ ...to, currency: value })
          }}
          className="flex-auto w-1/2"
        />
      </div>

      <div className="flex max-w-3xl mx-auto mb-8">
        <div className="flex-auto flex justify-end items-baseline w-1/2">
          <span className="mr-4 text-gray-500 font-bold">Transaction Fee:</span>

          <Listbox
            selected={feeCurrency}
            options={currencies}
            position="right"
            onChange={setFeeCurrency}>
            <Button outlined size="small" endIcon={<IconArrowDown />}>
              {selectedFeeCurrency ? selectedFeeCurrency.label : 'Select'}
            </Button>
          </Listbox>
        </div>

        <div className="flex-shrink-0 w-20"></div>

        <div className="flex-auto w-1/2">
          <TransactionInfoTable
            rows={[
              {
                label: 'Rate',
                value: '0.973526 Luna per bLUNA',
                infoText:
                  'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
              },
              {
                label: 'Minimum Received',
                value: '1.016921 bLUNA',
                infoText:
                  'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
              },
              {
                label: 'Trading Fee',
                value: '0.00309 bLUNA',
                infoText:
                  'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
              },
              {
                label: 'Tx Fee',
                value: '0.006798 Luna',
                infoText:
                  'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
              }
            ]}
          />
        </div>
      </div>

      <p className="max-w-lg mx-auto mb-8 text-sm text-center text-white italic opacity-50">
        The displaying number is the simulated result and can be different from
        the actual swap rate. Trade at your own risk.
      </p>

      <div className="text-center">
        <Button startIcon={<IconSwap />}>Swap</Button>
      </div>
    </div>
  )
}
