import { LCDClient } from '@terra-money/terra.js'
import { AddressProviderFromJson } from '@anchor-protocol/anchor.js'
import columbus4 from './address/columbus-4.json'

export const createClient = () => {
  return new LCDClient({
    URL: 'https://lcd.terra.dev',
    chainID: 'columbus-4'
  })
}

export const createAddressProvider = () => {
  return new AddressProviderFromJson(columbus4)
}
