import React from 'react'
import classNames from 'classnames'
import { Link, LinkProps } from 'react-router-dom'

interface BaseProps {
  component?: React.ElementType
  children: React.ReactNode
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  fullWidth?: boolean
  outlined?: boolean
  disabled?: boolean
  hasHover?: boolean
  size?: 'medium' | 'small'
  className?: string
}

interface HtmlButtonProps {
  type?: 'button' | 'reset' | 'submit'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

interface HtmlAnchorProps {
  href: string
  target?: '_blank' | '_self' | '_parent' | '_top'
}

type RouterProps = Pick<LinkProps, 'to' | 'replace'>

type ButtonPropsWithHtmlButton = BaseProps &
  HtmlButtonProps &
  Partial<Record<keyof HtmlAnchorProps, never>> &
  Partial<Record<keyof RouterProps, never>>

type ButtonPropsWithHtmlAnchor = BaseProps &
  HtmlAnchorProps &
  Partial<Record<keyof HtmlButtonProps, never>> &
  Partial<Record<keyof RouterProps, never>>

type ButtonPropsWithRouter = BaseProps &
  RouterProps &
  Partial<Record<keyof HtmlButtonProps, never>> &
  Partial<Record<keyof HtmlAnchorProps, never>>

export type ButtonProps =
  | ButtonPropsWithHtmlButton
  | ButtonPropsWithHtmlAnchor
  | ButtonPropsWithRouter

export const Button = React.forwardRef(
  (
    {
      component = 'button',
      children,
      startIcon,
      endIcon,
      fullWidth = false,
      outlined = false,
      disabled = false,
      hasHover = true,
      size = 'medium',
      className,
      to,
      href,
      target,
      type,
      onClick,
      ...props
    }: ButtonProps,
    ref
  ) => {
    let ButtonComponent = component

    const buttonProps: { [key: string]: unknown } = {}

    if (to) {
      ButtonComponent = Link

      buttonProps.to = to
    } else {
      if (ButtonComponent === 'button' && href) {
        ButtonComponent = 'a'

        buttonProps.href = href

        if (target) {
          buttonProps.target = target
          buttonProps.rel = 'noreferrer noopener'
        }
      }
    }

    if (ButtonComponent === 'button') {
      buttonProps.type = type === undefined ? 'button' : type
      buttonProps.onClick = (event: any) => {
        console.log('click')

        if (onClick) {
          onClick(event)
        }
      }
      buttonProps.disabled = disabled
    } else {
      if (ButtonComponent !== 'a' || !href) {
        buttonProps.role = 'button'
      }

      buttonProps['aria-disabled'] = disabled
    }

    return (
      <ButtonComponent
        ref={ref}
        className={classNames(
          'group items-center justify-center border border-current text-base transition-colors',
          {
            'flex w-full': fullWidth,
            'inline-flex': !fullWidth,
            'text-white': outlined,
            'hover:bg-current': outlined && hasHover,
            'text-blue-500 bg-current': !outlined,
            'hover:text-blue-600': !outlined && hasHover,
            'h-8 px-3.5 font-semibold rounded-md': size === 'small',
            'h-10 px-4 font-bold rounded-lg': size === 'medium',
            'opacity-50 pointer-events-none': disabled
          },
          className
        )}
        {...buttonProps}
        {...props}>
        <span
          className={classNames('flex items-center transition-colors', {
            'group-hover:text-gray-800': outlined && hasHover,
            'text-white': !outlined
          })}>
          {startIcon && (
            <span
              className={classNames('mr-2', {
                'text-sm': size === 'small',
                'text-md': size === 'medium'
              })}>
              {startIcon}
            </span>
          )}

          {children}

          {endIcon && (
            <span
              className={classNames('ml-2', {
                'text-sm': size === 'small',
                'text-md': size === 'medium'
              })}>
              {endIcon}
            </span>
          )}
        </span>
      </ButtonComponent>
    )
  }
)
