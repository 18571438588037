import { SvgIcon, SvgIconProps } from './SvgIcon'

export const IconLuna = ({ className }: SvgIconProps) => {
  return (
    <SvgIcon className={className}>
      <path
        fill="#f9d85e"
        d="M15.3,10.64a.16.16,0,0,1,.18.22A8,8,0,1,1,2.72,2,.16.16,0,0,1,3,2.1,9.41,9.41,0,0,0,8.76,10,10.15,10.15,0,0,0,15.3,10.64Zm.62-3.74A8,8,0,0,0,8,0,9.25,9.25,0,0,0,6.7.1a2.36,2.36,0,0,0-.4.08C3.54.78,4.24,2.12,8.06,3.5c7.58,2.74,7.58,2.74,7.86,3.4"
      />
      <path
        fill="#ef7730"
        d="M15.91,6.87c-.21-.49-.34-.63-3.89-1.93.77,1.91,2.12,3,3.88,4.3A8.91,8.91,0,0,0,16,8,6.92,6.92,0,0,0,15.91,6.87Z"
      />
    </SvgIcon>
  )
}
