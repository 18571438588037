import React, { createContext } from 'react'

interface Site {
  title: string
}

type SiteContextProviderProps = {
  children?: React.ReactNode
}

const initialState: Site = {
  title: 'Harpoon Ocean'
}

export const SiteContext = createContext<Site>(initialState)

export const SiteContextProvider = ({ children }: SiteContextProviderProps) => {
  return (
    <SiteContext.Provider value={initialState}>{children}</SiteContext.Provider>
  )
}
