import { Fragment } from 'react'
import { Menu as HuiMenu, Transition } from '@headlessui/react'
import classNames from 'classnames'

interface Item {
  title: React.ReactNode
  icon?: React.ReactNode
  disabled?: boolean
}

export type MenuProps = {
  children: React.ReactElement
  items: Item[]
  position?: 'left' | 'center' | 'right'
  className?: string
}

export const Menu = ({
  children,
  items,
  position = 'left',
  className
}: MenuProps) => {
  return (
    <HuiMenu
      as="div"
      className={classNames('relative inline-block', className)}>
      <HuiMenu.Button as={Fragment}>{children}</HuiMenu.Button>

      <Transition
        as={Fragment}
        enter="transition"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <HuiMenu.Items
          className={classNames(
            'absolute w-44 mt-2 py-2 text-gray-500 bg-white rounded-lg shadow',
            {
              'left-0 origin-top-left': position === 'left',
              'left-1/2 origin-top': position === 'center',
              'right-0 origin-top-right': position === 'right'
            }
          )}>
          {items.map(({ title, icon, disabled }, index) => {
            return (
              <HuiMenu.Item key={index} disabled={disabled}>
                {({ active }) => (
                  <button
                    className={classNames(
                      'flex items-center w-full px-4 py-2 font-semibold transition-colors',
                      {
                        'text-white bg-blue-500': active,
                        'opacity-50 pointer-events-none': disabled
                      }
                    )}
                    disabled={disabled}>
                    <span className="w-4 mr-4 text-md leading-none">
                      {icon}
                    </span>

                    {title}
                  </button>
                )}
              </HuiMenu.Item>
            )
          })}
        </HuiMenu.Items>
      </Transition>
    </HuiMenu>
  )
}
