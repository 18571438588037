import classNames from 'classnames'
import { NavLink, NavLinkProps } from 'react-router-dom'

interface BaseProps {
  title: string
}

interface HtmlAnchorProps {
  href: string
  target?: '_blank' | '_self' | '_parent' | '_top'
}

type RouterProps = Pick<
  NavLinkProps,
  'to' | 'replace' | 'exact' | 'strict' | 'isActive' | 'location'
>

type HeaderMenuLinkWithHtmlAnchor = BaseProps &
  HtmlAnchorProps &
  Partial<Record<keyof RouterProps, never>>

type HeaderMenuLinkWithRouter = BaseProps &
  RouterProps &
  Partial<Record<keyof HtmlAnchorProps, never>>

type HeaderMenuLink = HeaderMenuLinkWithHtmlAnchor | HeaderMenuLinkWithRouter

export type HeaderMenuProps = {
  links: HeaderMenuLink[]
  className?: string
}

export const HeaderMenu = ({ links, className }: HeaderMenuProps) => {
  return (
    <nav className={className}>
      <ul className="flex text-base text-gray-500 font-bold uppercase tracking-wide">
        {links.map(
          ({ title, to, exact = true, href, target, ...props }, index) => {
            let LinkComponent: React.ElementType = NavLink

            const linkProps: { [key: string]: unknown } = {}

            if (to) {
              linkProps.to = to
              linkProps.exact = exact
              linkProps.activeClassName = 'text-white'
            } else {
              LinkComponent = 'a'

              linkProps.href = href

              if (target) {
                linkProps.target = target
                linkProps.rel = 'noreferrer noopener'
              }
            }

            return (
              <li
                className={classNames({ 'mr-8': links.length - 1 !== index })}
                key={index}>
                <LinkComponent
                  className="hover:text-white transition-colors"
                  {...linkProps}
                  {...props}>
                  {title}
                </LinkComponent>
              </li>
            )
          }
        )}
      </ul>
    </nav>
  )
}
