import { useContext } from 'react'
import { SiteContext } from '../context/SiteContext'
import logoPng from '../assets/images/logo.png'
import logoCompactPng from '../assets/images/logo-compact.png'

export type LogoProps = {
  compact?: boolean
  className?: string
}

export const Logo = ({ compact = false, className }: LogoProps) => {
  const { title } = useContext(SiteContext)

  return (
    <img
      className={className}
      src={compact ? logoCompactPng : logoPng}
      alt={`${title} logo`}
    />
  )
}
