import { useMemo } from 'react'
import { Table } from '../components/Table'
import { Menu } from '../components/Menu'
import { Button } from '../components/Button'
import { IconLuna } from '../components/icons'

const Test = ({ value }: any) => {
  const [primary, secondary] = value

  return (
    <div>
      {primary}
      {secondary && <span className="block text-gray-500">{secondary}</span>}
    </div>
  )
}

export const Dashboard = () => {
  const columnsMy = useMemo(
    () => [
      {
        Header: 'Pool',
        accessor: 'pool'
      },
      {
        Header: 'APY',
        accessor: 'apy',
        Cell: ({ value }: any) => <Test value={value} />
      },
      {
        Header: 'Staked',
        accessor: 'staked',
        Cell: ({ value }: any) => <Test value={value} />
      },
      {
        Header: 'Rewards Received',
        accessor: 'rewardsReceived',
        Cell: ({ value }: any) => <Test value={value} />
      },
      {
        Header: 'Actions',
        accessor: 'actions'
      }
    ],
    []
  )

  const dataMy = useMemo(
    () => [
      {
        pool: 'bLuna-Luna LP',
        apy: ['1100%', '3% daily'],
        staked: ['1000 Luna + 1100 bLuna', '$12,600'],
        rewardsReceived: ['6 Luna + 5.5 bLuna', '$70.11'],
        actions: 'Provide, Withdraw'
      }
    ],
    []
  )

  const columnsAll = useMemo(
    () => [
      {
        Header: 'Pool',
        accessor: 'pool'
      },
      {
        Header: 'APY',
        accessor: 'apy',
        Cell: ({ value }: any) => <Test value={value} />
      },
      {
        Header: 'Ocean',
        accessor: 'liquidity'
      },
      {
        Header: '24hr Volume',
        accessor: 'volume24hr',
        Cell: ({ value }: any) => <Test value={value} />
      },
      {
        Header: 'Actions',
        accessor: 'actions'
      }
    ],
    []
  )

  const dataAll = useMemo(
    () => [
      {
        pool: 'bLuna-Luna LP',
        apy: ['1100%', '3% daily'],
        liquidity: '$10,000,000',
        volume24hr: ['$120,000', '$256,458 / 7 day'],
        actions: 'Provide'
      },
      {
        pool: 'Luna-UST LP',
        apy: ['100%', '0.27% daily'],
        liquidity: '$178,110,021',
        volume24hr: ['$1,114,000', '$256,458 / 7 day'],
        actions: 'Provide'
      },
      {
        pool: 'ANC-UST LP',
        apy: ['80%', '0.22% daily'],
        liquidity: '$10,000,000',
        volume24hr: ['$120,000', '$256,458 / 7 day'],
        actions: 'Provide'
      }
    ],
    []
  )

  return (
    <div className="container">
      <Table
        title="My Pools"
        columns={columnsMy}
        data={dataMy}
        highlight
        className="mb-10"
      />

      <Table
        title="All Pools"
        columns={columnsAll}
        data={dataAll}
        className="mb-10"
      />

      <Menu
        items={[
          { title: 'Luna', icon: <IconLuna /> },
          { title: 'bLuna', icon: <IconLuna /> },
          { title: 'cLuna', icon: <IconLuna /> },
          { title: 'dLuna', icon: <IconLuna />, disabled: true },
          { title: 'eLuna' }
        ]}>
        <Button>Menu</Button>
      </Menu>
    </div>
  )
}
