import { Extension, LCDClient } from '@terra-money/terra.js'
import {
  Abortable,
  AbortablePromise,
  AbortMethod
} from '../types/promise.interface'
import { abortPromise } from '../utils/promise'
import { mask } from '../utils/string'

type WalletConnectPayload = {
  address?: string
}

type ConnectInfo = {
  chainID: string // e.g. "columbus-4"
  fcd: string // e.g "https://fcd.terra.dev"
  lcd: string // e.g. "https://lcd.terra.dev"
  name: string // e.g. "mainnet"
}

export class WalletService {
  extension: Extension
  client: LCDClient
  address: string = ''
  info?: ConnectInfo
  constructor(extension: Extension, defaultClient: LCDClient) {
    this.extension = extension
    this.client = defaultClient
  }
  /**
   * Connects to the wallet via Chrome extension
   * @returns Abortable (promise with abort method)
   */
  connect(reconnect = false): Abortable {
    let abort: AbortMethod = () => {}
    if (reconnect && window.localStorage.terraStationConnected !== 'yes') {
      return {
        promise: Promise.reject('No consent, skipping connect.'),
        abort: () => {}
      }
    }
    const promise: Partial<AbortablePromise<void>> = new Promise<void>(
      (resolve, reject) => {
        abort = (msg) => reject(msg || 'Action cancelled by user')
        this.extension.once(
          'onConnect',
          async ({ address }: WalletConnectPayload) => {
            if (address) {
              this.address = address
              window.localStorage.terraStationConnected = 'yes'
              await this.getInfo()
              this.setClient()
              resolve()
            } else {
              reject('No address provided')
            }
          }
        )
        this.extension.connect()
      }
    )

    promise.abort = abort

    return { promise, abort: abortPromise(promise) }
  }

  getInfo() {
    if (!this.address) {
      throw new Error('Wallet not connected yet.')
    }
    return new Promise<void>((resolve) => {
      this.extension.once('onInfo', async (info) => {
        this.info = info
        resolve()
      })
      this.extension.info()
    })
  }

  getNetwork() {
    return this.info?.name || 'mainnet'
  }

  setClient() {
    if (!this.info) {
      throw new Error('Wallet not connected yet.')
    }
    this.client = new LCDClient({
      URL: this.info.lcd,
      chainID: this.info.chainID
    })
  }

  disconnect() {
    window.localStorage.terraStationConnected = ''
  }

  attemptReconnect() {
    if (window.localStorage.terraStationConnected === 'yes') {
      return this.connect()
    } else {
      return false
    }
  }

  async getBalance(): Promise<string> {
    if (!this.address) {
      throw new Error('Wallet not connected yet.')
    }
    const coins = await this.client.bank.balance(this.address)
    let uusd = '0'
    coins.map((coin) => {
      if (coin.denom === 'uusd') {
        uusd = coin.amount.div(1000000).toPrecision(2)
      }
      return coin
    })
    return uusd
  }

  getAddress(masked = true) {
    return masked ? mask(this.address) : this.address
  }
}

export function createWallet(defaultClient: LCDClient) {
  const extension = new Extension()
  return new WalletService(extension, defaultClient)
}
