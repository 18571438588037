import classNames from 'classnames'

export type HeadingProps = {
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children: React.ReactNode
  className?: string
}

export const Heading = ({
  component = 'h2',
  children,
  className
}: HeadingProps) => {
  const HeadingComponent: React.ElementType = component

  return (
    <HeadingComponent className={classNames('text-xl', className)}>
      {children}
    </HeadingComponent>
  )
}
