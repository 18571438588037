import { AssetBoxProps } from '../../components/AssetBox'
import { IconLuna } from '../../components/icons'

export const currencies: AssetBoxProps['currencies'] = [
  { label: 'Luna', value: 'luna', icon: <IconLuna /> },
  { label: 'bLuna', value: 'bluna', icon: <IconLuna /> },
  { label: 'cLuna', value: 'cluna', icon: <IconLuna /> },
  { label: 'dLuna', value: 'dluna', icon: <IconLuna /> },
  { label: 'eLuna', value: 'eluna', icon: <IconLuna /> }
]
