import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { SiteContextProvider } from './context/SiteContext'
import { WalletContextProvider } from './context/WalletContext'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './tailwind.css'

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <SiteContextProvider>
        <WalletContextProvider>
          <App />
        </WalletContextProvider>
      </SiteContextProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
