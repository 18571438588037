import { Button } from '../components/Button'
import { AssetBox } from '../components/AssetBox'
import { TransactionInfoTable } from '../components/TransactionInfoTable'
import {
  IconArrowDown,
  IconLuna,
  IconPlus,
  IconWithdraw
} from '../components/icons'
import { currencies } from '../data/mock/currencies'

export const Withdraw = () => {
  return (
    <div className="container">
      <div className="max-w-md mx-auto mb-6">
        <AssetBox
          title="LP"
          balance={1234103}
          amount={600}
          currencies={currencies}
          showMax
          onInputChange={() => {}}
          onCurrencyChange={() => {}}
          className="mb-4"
        />

        <div className="flex justify-end items-baseline">
          <span className="mr-4 text-gray-500 font-bold">Transaction Fee:</span>

          <Button outlined size="small" endIcon={<IconArrowDown />}>
            Luna
          </Button>
        </div>
      </div>

      <div className="flex justify-center mb-8 text-md text-blue-500">
        <IconArrowDown />
      </div>

      <div className="flex items-center mb-10">
        <div className="flex-auto flex justify-end w-1/2">
          <span className="mr-4 text-xl leading-none">1,000.231</span>

          <div className="flex items-center">
            <IconLuna className="mr-2 text-xl leading-none" />
            <span className="text-lg leading-none font-semibold">Luna</span>
          </div>
        </div>

        <IconPlus className="flex-shrink-0 mx-6 text-gray-500" />

        <div className="flex-auto flex w-1/2">
          <span className="mr-4 text-xl leading-none">1,101.23</span>

          <div className="flex items-center">
            <IconLuna className="mr-2 text-xl leading-none" />
            <span className="text-lg leading-none font-semibold">bLuna</span>
          </div>
        </div>
      </div>

      <div className="max-w-sm mx-auto mb-8">
        <TransactionInfoTable
          rows={[
            {
              label: 'Luna price',
              value: '1.161793 Luna per LP'
            },
            {
              label: 'bLUNA price',
              value: '1.192693 bLUNA per LP'
            },
            {
              label: 'LP from Tx',
              value: '860.738078 LP',
              infoText:
                'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
            },
            {
              label: 'Pool Share after Tx',
              value: '0.27 %',
              infoText:
                'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
            },
            {
              label: 'Tx Fee',
              value: '0.006798 Luna',
              infoText:
                'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
            }
          ]}
        />
      </div>

      <p className="max-w-lg mx-auto mb-8 text-sm text-center text-white italic opacity-50">
        The displaying number is the simulated result and can be different from
        the actual swap rate. Trade at your own risk.
      </p>

      <div className="text-center">
        <Button endIcon={<IconWithdraw />}>Withdraw Ocean</Button>
      </div>
    </div>
  )
}
