import { SvgIcon, SvgIconProps } from './SvgIcon'

export const IconInfo = ({ className }: SvgIconProps) => {
  return (
    <SvgIcon className={className}>
      <path d="M8,16a8,8,0,1,1,8-8A8,8,0,0,1,8,16ZM8,1a7,7,0,1,0,7,7A7,7,0,0,0,8,1Z" />
      <path d="M8.4,4.57A.82.82,0,0,0,9,4.3a1,1,0,0,0,.26-.66.74.74,0,0,0-.19-.5.66.66,0,0,0-.51-.2A.83.83,0,0,0,8,3.21a.89.89,0,0,0-.27.65.67.67,0,0,0,.21.5A.63.63,0,0,0,8.4,4.57Zm-.82,8.37a1.43,1.43,0,0,0,.89-.28A2.5,2.5,0,0,0,9,12.12H9l-.16-.32a2.81,2.81,0,0,1-.35.28.6.6,0,0,1-.28.08.19.19,0,0,1-.14-.08.49.49,0,0,1,0-.23h0v-.12L8.89,6l-.28-.13L6.8,6.15l-.09.43.76.29c0,.4-.19,1.4-.42,3-.17,1.19-.27,2-.32,2.32a.64.64,0,0,0,.19.57A1,1,0,0,0,7.58,12.94Z" />
    </SvgIcon>
  )
}
