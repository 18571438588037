import { useCallback, useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { WalletContext } from '../context/WalletContext'
import { Button } from './Button'
import { IconClose, IconWallet } from './icons'

export const ButtonWallet = () => {
  const { wallet } = useContext(WalletContext)
  const [address, setAddress] = useState('')
  const [network, setNetwork] = useState('')

  const connect = useCallback(
    async (reconnect = false) => {
      const { promise } = wallet.connect(reconnect)

      try {
        await promise
        setAddress(wallet.getAddress())
        setNetwork(wallet.getNetwork())
      } catch (e) {}
    },
    [wallet]
  )

  const disconnect = useCallback(() => {
    wallet.disconnect()
    setAddress('')
    setNetwork('')
  }, [wallet])

  useEffect(() => {
    connect(true)
  }, [connect])

  return (
    <div className="relative">
      <Button
        outlined
        onClick={() => connect()}
        startIcon={<IconWallet />}
        component={address ? 'span' : undefined}
        className={classNames({ 'pr-11 cursor-default': address })}
        hasHover={!address}>
        {address || 'Connect Wallet'}
        {network ? ` | ${network}` : null}
      </Button>

      {address && (
        <button
          className="absolute top-1/2 right-2.5 p-1.5 transform -translate-y-1/2 text-md hover:text-blue-500 transition-colors"
          onClick={() => disconnect()}
          type="button"
          aria-label="Disconnect Wallet">
          <IconClose />
        </button>
      )}
    </div>
  )
}
